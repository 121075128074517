.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  text-align: end;
  border-bottom: 1pt lightgray solid;
}

.title {
  font-family: "Roboto Slab", serif;
  font-size: 2.3rem;
}

i {
  font-size: 30px !important;
  color: black;
}

i.small {
  font-size: 15px !important;
}

i.fa-check-circle {
  color: lightgray;
}
i.fa-check-circle.revealed {
  color: black;
}

.popup-box {
  z-index: 1000;
  text-align: left;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.icons {
  position: absolute;
  left: 15px;
}

.emoji {
  font-size: 0.85rem;
}

@media only screen and (max-width: 900px) {
  .App {
    height: 98vh;
  }

  .title {
    font-family: "Roboto Slab", serif;
    font-size: 2rem;
  }

  i {
    font-size: 25px !important;
  }
}
