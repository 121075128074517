.gridGuidesHolder {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.gridGuide {
  width: 100%;
  text-align: center;
  letter-spacing: 0.6rem;
}

.gridGuide > p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.gridHolder {
  padding: 10px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr);
  box-sizing: border-box;
}

.row {
  width: calc(66 * 5 * 2px);
  height: 66px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.25fr 1fr 0.5fr 1fr 1fr 1fr 1fr 0.25fr 1fr; /* repeat(11, 1fr); */
  grid-template-rows: 1fr;
  box-sizing: border-box;
}

.cell {
  background-color: white;
  border: 2px lightgray solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  color: black;
}

.cell.signedCell {
  color: lightgray;
  font-weight: 300;
}

.curr {
  /* background-color: hotpink; */
}

span {
  font-size: 2rem;
  font-weight: bold;
  text-transform: capitalize;
}

.cell.guessed {
  border: 2px grey solid;
  animation-name: tileGuess;
  animation-duration: 75ms;
}

.cell.green {
  --background-color: rgb(121, 167, 107);
  --border-color: rgb(121, 167, 107);
}

.cell.yellow {
  --background-color: rgb(198, 179, 102);
  --border-color: rgb(198, 179, 102);
}

.cell.grey {
  --background-color: rgb(121, 121, 126);
  --border-color: rgb(121, 121, 126);
}

.cell.decimalCell {
  border: transparent;
  color: lightgray;
  --background-color: white;
  /* width: 5px; */
}

.cell.commaCell {
  border: transparent;
  color: lightgray !important;
  font-family: "Work Sans", sans-serif; /* more readable comma in this font */
}
.cell.Ind0 {
  animation: flip 1s ease forwards;
}

.cell.Ind1 {
  animation: flip 1s ease forwards 100ms;
}

.cell.Ind2 {
  animation: flip 1s ease forwards 200ms;
}

.cell.Ind3 {
  animation: flip 1s ease forwards 300ms;
}
.cell.Ind4 {
  animation: flip 1s ease forwards 400ms;
}
.cell.Ind5 {
  animation: flip 1s ease forwards 500ms;
}

.cell.Ind6 {
  animation: flip 1s ease forwards 600ms;
}

.cell.Ind7 {
  animation: flip 1s ease forwards 700ms;
}

.cell.Ind8 {
  animation: flip 1s ease forwards 800ms;
}
.cell.Ind9 {
  animation: flip 1s ease forwards 900ms;
}

.cell.Ind10 {
  animation: flip 1s ease forwards 1.1s;
}
.cell.Ind11 {
  animation: flip 1s ease forwards 1.2s;
}

/* WHEN SOLVED */

.row.solved-row > .cell.Ind0 {
  animation: flip 1s ease forwards, solved-wave 0.75s ease forwards 1.2s;
}

.row.solved-row > .cell.Ind1 {
  animation: flip 1s ease forwards 100ms, solved-wave 0.75s ease forwards 1.3s;
}

.row.solved-row > .cell.Ind2 {
  animation: flip 1s ease forwards 200ms, solved-wave 0.75s ease forwards 1.4s;
}

.row.solved-row > .cell.Ind3 {
  animation: flip 1s ease forwards 300ms, solved-wave 0.75s ease forwards 1.5s;
}
.row.solved-row > .cell.Ind4 {
  animation: flip 1s ease forwards 400ms, solved-wave 0.75s ease forwards 1.6s;
}
.row.solved-row > .cell.Ind5 {
  animation: flip 1s ease forwards 500ms, solved-wave 0.75s ease forwards 1.7s;
}

.row.solved-row > .cell.Ind6 {
  animation: flip 1s ease forwards 600ms, solved-wave 0.75s ease forwards 1.8s;
}

.row.solved-row > .cell.Ind7 {
  animation: flip 1s ease forwards 700ms, solved-wave 0.75s ease forwards 1.9s;
}

.row.solved-row > .cell.Ind8 {
  animation: flip 1s ease forwards 800ms, solved-wave 0.75s ease forwards 2s;
}
.row.solved-row > .cell.Ind9 {
  animation: flip 1s ease forwards 900ms, solved-wave 0.75s ease forwards 2.1s;
}

.row.solved-row > .cell.Ind10 {
  animation: flip 1s ease forwards 1s, solved-wave 0.75s ease forwards 2.2s;
}
.row.solved-row > .cell.Ind11 {
  animation: flip 1s ease forwards 1.1s, solved-wave 0.75s ease forwards 2.3s;
}

/* INVALID ANS */

.row.invalid {
  /* background-color: blue; */
  animation: invalidShake 0.15s ease-in-out forwards;
  animation-iteration-count: 4;
}

/* *********** */
/* ANIMATIONS */
/* *********** */

@keyframes tileGuess {
  50% {
    margin: 0px;
  }
}

@keyframes invalidShake {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(-7px, 0);
  }
  66% {
    transform: translate(7px, 0);
  }
  100% {
    transform: translate(0px, 0);
  }
}

@keyframes solved-wave {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -30px);
  }
  100% {
    transform: translate(0px, 0);
  }
}

/* flip animation */

@keyframes flip {
  0% {
    transform: rotateX(0);
    border-color: grey;
    background-color: white;
    color: black;
  }
  45% {
    transform: rotateX(90deg);
    border-color: grey;
    background-color: white;
    color: black;
  }
  55% {
    transform: rotateX(90deg);
    border-color: var(--border-color);
    background-color: var(--background-color);
    color: white;
  }
  100% {
    transform: rotateX(0);
    border-color: var(--border-color);
    background-color: var(--background-color);
    color: white;
  }
}

/* *********** */
/* MOBILE */
/* *********** */

@media only screen and (max-width: 900px) {
  .gridGuide {
    letter-spacing: 0rem;
  }
  .row {
    width: calc(35 * 5 * 2px);
    height: 35px;
  }

  span {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: capitalize;
  }
}
