body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  text-align: end;
  border-bottom: 1pt lightgray solid;
}

.title {
  font-family: "Roboto Slab", serif;
  font-size: 2.3rem;
}

i {
  font-size: 30px !important;
  color: black;
}

i.small {
  font-size: 15px !important;
}

i.fa-check-circle {
  color: lightgray;
}
i.fa-check-circle.revealed {
  color: black;
}

.popup-box {
  z-index: 1000;
  text-align: left;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.icons {
  position: absolute;
  left: 15px;
}

.emoji {
  font-size: 0.85rem;
}

@media only screen and (max-width: 900px) {
  .App {
    height: 98vh;
  }

  .title {
    font-family: "Roboto Slab", serif;
    font-size: 2rem;
  }

  i {
    font-size: 25px !important;
  }
}

.gridGuidesHolder {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.gridGuide {
  width: 100%;
  text-align: center;
  letter-spacing: 0.6rem;
}

.gridGuide > p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.gridHolder {
  padding: 10px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr);
  box-sizing: border-box;
}

.row {
  width: calc(66 * 5 * 2px);
  height: 66px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.25fr 1fr 0.5fr 1fr 1fr 1fr 1fr 0.25fr 1fr; /* repeat(11, 1fr); */
  grid-template-rows: 1fr;
  box-sizing: border-box;
}

.cell {
  background-color: white;
  border: 2px lightgray solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  color: black;
}

.cell.signedCell {
  color: lightgray;
  font-weight: 300;
}

.curr {
  /* background-color: hotpink; */
}

span {
  font-size: 2rem;
  font-weight: bold;
  text-transform: capitalize;
}

.cell.guessed {
  border: 2px grey solid;
  -webkit-animation-name: tileGuess;
          animation-name: tileGuess;
  -webkit-animation-duration: 75ms;
          animation-duration: 75ms;
}

.cell.green {
  --background-color: rgb(121, 167, 107);
  --border-color: rgb(121, 167, 107);
}

.cell.yellow {
  --background-color: rgb(198, 179, 102);
  --border-color: rgb(198, 179, 102);
}

.cell.grey {
  --background-color: rgb(121, 121, 126);
  --border-color: rgb(121, 121, 126);
}

.cell.decimalCell {
  border: transparent;
  color: lightgray;
  --background-color: white;
  /* width: 5px; */
}

.cell.commaCell {
  border: transparent;
  color: lightgray !important;
  font-family: "Work Sans", sans-serif; /* more readable comma in this font */
}
.cell.Ind0 {
  -webkit-animation: flip 1s ease forwards;
          animation: flip 1s ease forwards;
}

.cell.Ind1 {
  -webkit-animation: flip 1s ease forwards 100ms;
          animation: flip 1s ease forwards 100ms;
}

.cell.Ind2 {
  -webkit-animation: flip 1s ease forwards 200ms;
          animation: flip 1s ease forwards 200ms;
}

.cell.Ind3 {
  -webkit-animation: flip 1s ease forwards 300ms;
          animation: flip 1s ease forwards 300ms;
}
.cell.Ind4 {
  -webkit-animation: flip 1s ease forwards 400ms;
          animation: flip 1s ease forwards 400ms;
}
.cell.Ind5 {
  -webkit-animation: flip 1s ease forwards 500ms;
          animation: flip 1s ease forwards 500ms;
}

.cell.Ind6 {
  -webkit-animation: flip 1s ease forwards 600ms;
          animation: flip 1s ease forwards 600ms;
}

.cell.Ind7 {
  -webkit-animation: flip 1s ease forwards 700ms;
          animation: flip 1s ease forwards 700ms;
}

.cell.Ind8 {
  -webkit-animation: flip 1s ease forwards 800ms;
          animation: flip 1s ease forwards 800ms;
}
.cell.Ind9 {
  -webkit-animation: flip 1s ease forwards 900ms;
          animation: flip 1s ease forwards 900ms;
}

.cell.Ind10 {
  -webkit-animation: flip 1s ease forwards 1.1s;
          animation: flip 1s ease forwards 1.1s;
}
.cell.Ind11 {
  -webkit-animation: flip 1s ease forwards 1.2s;
          animation: flip 1s ease forwards 1.2s;
}

/* WHEN SOLVED */

.row.solved-row > .cell.Ind0 {
  -webkit-animation: flip 1s ease forwards, solved-wave 0.75s ease forwards 1.2s;
          animation: flip 1s ease forwards, solved-wave 0.75s ease forwards 1.2s;
}

.row.solved-row > .cell.Ind1 {
  -webkit-animation: flip 1s ease forwards 100ms, solved-wave 0.75s ease forwards 1.3s;
          animation: flip 1s ease forwards 100ms, solved-wave 0.75s ease forwards 1.3s;
}

.row.solved-row > .cell.Ind2 {
  -webkit-animation: flip 1s ease forwards 200ms, solved-wave 0.75s ease forwards 1.4s;
          animation: flip 1s ease forwards 200ms, solved-wave 0.75s ease forwards 1.4s;
}

.row.solved-row > .cell.Ind3 {
  -webkit-animation: flip 1s ease forwards 300ms, solved-wave 0.75s ease forwards 1.5s;
          animation: flip 1s ease forwards 300ms, solved-wave 0.75s ease forwards 1.5s;
}
.row.solved-row > .cell.Ind4 {
  -webkit-animation: flip 1s ease forwards 400ms, solved-wave 0.75s ease forwards 1.6s;
          animation: flip 1s ease forwards 400ms, solved-wave 0.75s ease forwards 1.6s;
}
.row.solved-row > .cell.Ind5 {
  -webkit-animation: flip 1s ease forwards 500ms, solved-wave 0.75s ease forwards 1.7s;
          animation: flip 1s ease forwards 500ms, solved-wave 0.75s ease forwards 1.7s;
}

.row.solved-row > .cell.Ind6 {
  -webkit-animation: flip 1s ease forwards 600ms, solved-wave 0.75s ease forwards 1.8s;
          animation: flip 1s ease forwards 600ms, solved-wave 0.75s ease forwards 1.8s;
}

.row.solved-row > .cell.Ind7 {
  -webkit-animation: flip 1s ease forwards 700ms, solved-wave 0.75s ease forwards 1.9s;
          animation: flip 1s ease forwards 700ms, solved-wave 0.75s ease forwards 1.9s;
}

.row.solved-row > .cell.Ind8 {
  -webkit-animation: flip 1s ease forwards 800ms, solved-wave 0.75s ease forwards 2s;
          animation: flip 1s ease forwards 800ms, solved-wave 0.75s ease forwards 2s;
}
.row.solved-row > .cell.Ind9 {
  -webkit-animation: flip 1s ease forwards 900ms, solved-wave 0.75s ease forwards 2.1s;
          animation: flip 1s ease forwards 900ms, solved-wave 0.75s ease forwards 2.1s;
}

.row.solved-row > .cell.Ind10 {
  -webkit-animation: flip 1s ease forwards 1s, solved-wave 0.75s ease forwards 2.2s;
          animation: flip 1s ease forwards 1s, solved-wave 0.75s ease forwards 2.2s;
}
.row.solved-row > .cell.Ind11 {
  -webkit-animation: flip 1s ease forwards 1.1s, solved-wave 0.75s ease forwards 2.3s;
          animation: flip 1s ease forwards 1.1s, solved-wave 0.75s ease forwards 2.3s;
}

/* INVALID ANS */

.row.invalid {
  /* background-color: blue; */
  -webkit-animation: invalidShake 0.15s ease-in-out forwards;
          animation: invalidShake 0.15s ease-in-out forwards;
  -webkit-animation-iteration-count: 4;
          animation-iteration-count: 4;
}

/* *********** */
/* ANIMATIONS */
/* *********** */

@-webkit-keyframes tileGuess {
  50% {
    margin: 0px;
  }
}

@keyframes tileGuess {
  50% {
    margin: 0px;
  }
}

@-webkit-keyframes invalidShake {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  33% {
    -webkit-transform: translate(-7px, 0);
            transform: translate(-7px, 0);
  }
  66% {
    -webkit-transform: translate(7px, 0);
            transform: translate(7px, 0);
  }
  100% {
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
  }
}

@keyframes invalidShake {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  33% {
    -webkit-transform: translate(-7px, 0);
            transform: translate(-7px, 0);
  }
  66% {
    -webkit-transform: translate(7px, 0);
            transform: translate(7px, 0);
  }
  100% {
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
  }
}

@-webkit-keyframes solved-wave {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, -30px);
            transform: translate(0, -30px);
  }
  100% {
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
  }
}

@keyframes solved-wave {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, -30px);
            transform: translate(0, -30px);
  }
  100% {
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
  }
}

/* flip animation */

@-webkit-keyframes flip {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    border-color: grey;
    background-color: white;
    color: black;
  }
  45% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
    border-color: grey;
    background-color: white;
    color: black;
  }
  55% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
    border-color: var(--border-color);
    background-color: var(--background-color);
    color: white;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    border-color: var(--border-color);
    background-color: var(--background-color);
    color: white;
  }
}

@keyframes flip {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    border-color: grey;
    background-color: white;
    color: black;
  }
  45% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
    border-color: grey;
    background-color: white;
    color: black;
  }
  55% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
    border-color: var(--border-color);
    background-color: var(--background-color);
    color: white;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    border-color: var(--border-color);
    background-color: var(--background-color);
    color: white;
  }
}

/* *********** */
/* MOBILE */
/* *********** */

@media only screen and (max-width: 900px) {
  .gridGuide {
    letter-spacing: 0rem;
  }
  .row {
    width: calc(35 * 5 * 2px);
    height: 35px;
  }

  span {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.kybrdKey {
  background-color: lightgrey;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 10pt;
  height: 58px;
  min-width: 43px;
  margin: 4px 3px 4px 3px;
  padding: 0 15px 0 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: black;
}

.kybrdKey.green {
  -webkit-animation: colorUpdate 10ms ease forwards 2.2s;
          animation: colorUpdate 10ms ease forwards 2.2s;
  --background-color: rgb(121, 167, 107);
}
.kybrdKey.grey {
  -webkit-animation: colorUpdate 10ms ease forwards 2.2s;
          animation: colorUpdate 10ms ease forwards 2.2s;
  --background-color: rgb(121, 121, 126);
}
.kybrdKey.yellow {
  -webkit-animation: colorUpdate 10ms ease forwards 2.2s;
          animation: colorUpdate 10ms ease forwards 2.2s;
  --background-color: rgb(198, 179, 102);
}
.board {
  margin-bottom: 5px;
}

.kybrdRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 15pt;
}

@-webkit-keyframes colorUpdate {
  100% {
    background-color: var(--background-color);
  }
}

@keyframes colorUpdate {
  100% {
    background-color: var(--background-color);
  }
}

/* *********** */
/* MOBILE */
/* *********** */

@media only screen and (max-width: 900px) {
  .kybrdKey {
    font-size: 0.85rem;
    max-height: 6vh;
    width: 25vw;
    margin: 4px;
  }

  .kybrdRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .kybrdRowMobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
  .kybrdRowMobile > .kybrdKey {
    width: 37.75vw;
    align-self: center;
  }
}

/* globeHolder was used when MyGlobe was stationed to top right corner */
/* .globeHolder {
  position: fixed;
  top: calc(4rem + 10px);
  right: 10px;
  width: 200px;
  height: 200px;
} */

.globeHolder.mobile {
  display: none;
}

.globe-msg {
  text-align: center;
  font-size: 9pt;
}

#mapHolder {
  border: grey 3pt solid !important;
  border-radius: 5px;
  width: 660px; /* old: 450px; */
  height: 260px;
  min-height: 200px; /* new */
}

@media only screen and (max-width: 900px) {
  #mapHolder {
    width: 70vw;
    height: 20vh;
  }
}

.popup-box {
  position: fixed;
  background: #ffffff8f;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.content-box {
  position: relative;
  max-width: 550px;
  width: 50%;
  min-width: 470px;
  margin: 0 auto;
  height: auto;
  max-height: 85vh;
  margin-top: 5vh;
  background: #fff;
  border-radius: 8px;
  padding: 18px;
  overflow: auto;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.267);
}

.content-box.solve {
  margin-top: 15%;
}

h3 {
  text-align: center;
  display: inline;
  position: relative;
  left: 100px;
}

h4.endtext {
  text-align: center;
  font-size: 0.8rem;
}

.subtitle-holder {
  width: calc(100% - 70px);
  position: relative;
  display: inline-block;
  border-bottom: none !important;
  text-align: center;
}

.subtitle {
  font-family: "Roboto Slab", serif;
  font-size: 1.5rem;
  text-align: center;
}

.subtitle > i {
  font-size: 1.5rem !important;
}

.content-box > div {
  border-bottom: 1pt lightgray solid;
}

.non-border-content-box {
  border-bottom: none !important;
}
/* .content-box > * {
  border: hotpink 1pt solid;
} */

.example-grid-holder {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  box-sizing: border-box;
}

.example-grid-row {
  width: calc(45 * 5 * 2px);
  height: 45px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.25fr 1fr 0.5fr 1fr 1fr 1fr 1fr 0.25fr 1fr; /* repeat(11, 1fr); */
  grid-template-rows: 1fr;
  box-sizing: border-box;
}

.example-grid-cell {
  background-color: white;
  border: 2px gray solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  color: black;
}
.example-grid-cell.comma {
  border: none;
  color: grey;
}

.example-grid-cell.decimal {
  border: none;
  color: grey;
}

.example-grid-cell.green {
  background-color: rgb(121, 167, 107);
  border: none;
  color: white;
}
.example-grid-cell.yellow {
  background-color: rgb(198, 179, 102);
  border: none;
  color: white;
}
.example-grid-cell.grey {
  background-color: rgb(121, 121, 126);
  border: none;
  color: white;
}

.example-grid-cell > span {
  font-size: 1rem;
}

p {
  font-size: 0.85rem;
}

@media only screen and (max-width: 900px) {
  .content-box {
    position: relative;
    max-width: 90vw;
    width: 75%;
    min-width: 80vw;
    margin: 0 auto;
    height: auto;
    max-height: 85vh;
    margin-top: 5vh;
    background: #fff;
    border-radius: 8px;
    padding: 18px;
    overflow: auto;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.267);
  }
  .example-grid-row {
    width: calc(26 * 5 * 2px);
    height: 26px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.25fr 1fr 0.5fr 1fr 1fr 1fr 1fr 0.25fr 1fr; /* repeat(11, 1fr); */
    grid-template-rows: 1fr;
    box-sizing: border-box;
  }
  .example-grid-cell > span {
    font-size: 0.8rem;
  }

  h4.endtext {
    font-size: 0.8rem;
  }

  .content-box.solve {
    margin-top: 25%;
  }
  .subtitle {
    font-size: 1.35rem;
  }
}

