.popup-box {
  position: fixed;
  background: #ffffff8f;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.content-box {
  position: relative;
  max-width: 550px;
  width: 50%;
  min-width: 470px;
  margin: 0 auto;
  height: auto;
  max-height: 85vh;
  margin-top: 5vh;
  background: #fff;
  border-radius: 8px;
  padding: 18px;
  overflow: auto;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.267);
}

.content-box.solve {
  margin-top: 15%;
}

h3 {
  text-align: center;
  display: inline;
  position: relative;
  left: 100px;
}

h4.endtext {
  text-align: center;
  font-size: 0.8rem;
}

.subtitle-holder {
  width: calc(100% - 70px);
  position: relative;
  display: inline-block;
  border-bottom: none !important;
  text-align: center;
}

.subtitle {
  font-family: "Roboto Slab", serif;
  font-size: 1.5rem;
  text-align: center;
}

.subtitle > i {
  font-size: 1.5rem !important;
}

.content-box > div {
  border-bottom: 1pt lightgray solid;
}

.non-border-content-box {
  border-bottom: none !important;
}
/* .content-box > * {
  border: hotpink 1pt solid;
} */

.example-grid-holder {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  box-sizing: border-box;
}

.example-grid-row {
  width: calc(45 * 5 * 2px);
  height: 45px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.25fr 1fr 0.5fr 1fr 1fr 1fr 1fr 0.25fr 1fr; /* repeat(11, 1fr); */
  grid-template-rows: 1fr;
  box-sizing: border-box;
}

.example-grid-cell {
  background-color: white;
  border: 2px gray solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  color: black;
}
.example-grid-cell.comma {
  border: none;
  color: grey;
}

.example-grid-cell.decimal {
  border: none;
  color: grey;
}

.example-grid-cell.green {
  background-color: rgb(121, 167, 107);
  border: none;
  color: white;
}
.example-grid-cell.yellow {
  background-color: rgb(198, 179, 102);
  border: none;
  color: white;
}
.example-grid-cell.grey {
  background-color: rgb(121, 121, 126);
  border: none;
  color: white;
}

.example-grid-cell > span {
  font-size: 1rem;
}

p {
  font-size: 0.85rem;
}

@media only screen and (max-width: 900px) {
  .content-box {
    position: relative;
    max-width: 90vw;
    width: 75%;
    min-width: 80vw;
    margin: 0 auto;
    height: auto;
    max-height: 85vh;
    margin-top: 5vh;
    background: #fff;
    border-radius: 8px;
    padding: 18px;
    overflow: auto;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.267);
  }
  .example-grid-row {
    width: calc(26 * 5 * 2px);
    height: 26px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.25fr 1fr 0.5fr 1fr 1fr 1fr 1fr 0.25fr 1fr; /* repeat(11, 1fr); */
    grid-template-rows: 1fr;
    box-sizing: border-box;
  }
  .example-grid-cell > span {
    font-size: 0.8rem;
  }

  h4.endtext {
    font-size: 0.8rem;
  }

  .content-box.solve {
    margin-top: 25%;
  }
  .subtitle {
    font-size: 1.35rem;
  }
}
