.kybrdKey {
  background-color: lightgrey;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 10pt;
  height: 58px;
  min-width: 43px;
  margin: 4px 3px 4px 3px;
  padding: 0 15px 0 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: black;
}

.kybrdKey.green {
  animation: colorUpdate 10ms ease forwards 2.2s;
  --background-color: rgb(121, 167, 107);
}
.kybrdKey.grey {
  animation: colorUpdate 10ms ease forwards 2.2s;
  --background-color: rgb(121, 121, 126);
}
.kybrdKey.yellow {
  animation: colorUpdate 10ms ease forwards 2.2s;
  --background-color: rgb(198, 179, 102);
}
.board {
  margin-bottom: 5px;
}

.kybrdRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 15pt;
}

@keyframes colorUpdate {
  100% {
    background-color: var(--background-color);
  }
}

/* *********** */
/* MOBILE */
/* *********** */

@media only screen and (max-width: 900px) {
  .kybrdKey {
    font-size: 0.85rem;
    max-height: 6vh;
    width: 25vw;
    margin: 4px;
  }

  .kybrdRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .kybrdRowMobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
  .kybrdRowMobile > .kybrdKey {
    width: 37.75vw;
    align-self: center;
  }
}
